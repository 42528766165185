// consumer_web_policyConfig_delSubsidyPolicy 【576】删除出差补助政策
const __request = require(`./__request/__request_contentType_json`)
const consumer_web_policyConfig_delSubsidyPolicy = (data) => {
    let pParameter = {
        method: 'post',
        urlSuffix: '/consumer/web/policyConfig/delSubsidyPolicy',
        data: data
    }
    return __request(pParameter)
}
export default consumer_web_policyConfig_delSubsidyPolicy