import DialogBox from '@/component/dialog'
import refund_hange_policy_get from '@/lib/data-service/haolv-default/consumer_t-tp-refund-change-policy_get'
import consumer_web_policyConfig_getSubsidyPolicyList from '@/lib/data-service/haolv-default/consumer_web_policyConfig_getSubsidyPolicyList'
import consumer_web_policyConfig_delSubsidyPolicy from '@/lib/data-service/haolv-default/consumer_web_policyConfig_delSubsidyPolicy'



export default {
  name: "evectionCar",
  data() {
    return {
      loadingSwitch: true,
      policyList:[],
    }
  },
  components: { DialogBox, },
  created() {

  },
  activated(){
    this.loadingSwitch = true;
    this.getData();
  },
  mounted() {

  },
  methods: {
    getData(){
      this.loadingSwitch = true;
      consumer_web_policyConfig_getSubsidyPolicyList().then((res) => {
        this.policyList = res.datas.results;
        this.loadingSwitch = false;
        // this.policyList = [{
        //   positionNames: '默认飞机',
        //   subsidyPolicy: {
        //     id: '1',
        //     priceResultList: [
        //       {elseSort: 1,price: 1,sortId: 1,sortName: '123'},
        //       {elseSort: 1,price: 1,sortId: 1,sortName: '123'},
        //     ],
        //   }
        // }];
      });
    },
    _clickAddSubsidy() {
      this.$router.push({
        name: 'admin-evection-car-policy-addEdit',
      })
    },
    _clickTemplateList() {
      this.$router.push({
        name: 'admin-subsidy-template-list',
      })
    },
    clickDel(item,index) {
      this.$confirm('确定删除此政策吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.loadingSwitch = true;
        consumer_web_policyConfig_delSubsidyPolicy({ids: [item.subsidyPolicy.id]})
            .then(res => {
              this.getData()
              this.$message({
                message: '删除成功',
                type: 'success'
              });
            })
            .finally(ref => {
              this.loadingSwitch = false;
              if (ref.code != '00000') {
                this.$message.error('删除失败');
              }
            })
      }).catch(() => {
      });
    },
    clickEdit(item,index) {
      this.$router.push({
        name: 'admin-evection-car-policy-addEdit',
        query: {
          id: item.subsidyPolicy.id,
        }
      })
    },
  }
}